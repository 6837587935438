import * as yup from 'yup';

export const schema = yup.object().shape({
  cod_loja: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  cod_cc: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  cod_pessoa: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  cod_categoria: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  cod_finalizadora: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
  dta_emissao: yup.string().required(),
  dta_entrada: yup.string().required(),
  val_parcela: yup.string().required(),
  num_docto: yup.number().required(),
  condicao: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  dta_vencimento: yup.string().required(),
  dta_quitada: yup.string().when('flg_quitado', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  flg_quitado: yup.boolean().notRequired(),
  flg_leitura_vda: yup.boolean().notRequired(),
  // num_pdv: yup.number().notRequired(),
  // num_cupom_fiscal: yup.number().notRequired(),
  // des_observacao: yup.string().notRequired(),
});
