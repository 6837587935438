import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, ReactNode, useContext } from 'react';
import { schema } from './validations';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';

type FormType = {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control<FieldValues, any>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
};

type ContasAReceberContextData = {
  formContasAReceber: FormType;
};

export const ContasAReceberContext = createContext(
  {} as ContasAReceberContextData,
);

interface ContasAReceberContextProviderProps {
  children: ReactNode;
}

export function ContasAReceberContextProvider({
  children,
}: ContasAReceberContextProviderProps): JSX.Element {
  /**
   * Form ContasAReceber
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  return (
    <ContasAReceberContext.Provider
      value={{
        formContasAReceber: {
          watch,
          reset,
          control,
          register,
          setValue,
          setError,
          getValues,
          setFocus,
          formState,
          clearErrors,
          handleSubmit,
          trigger,
        },
      }}
    >
      {children}
    </ContasAReceberContext.Provider>
  );
}

export const useContasAReceber = (): ContasAReceberContextData => {
  return useContext(ContasAReceberContext);
};
