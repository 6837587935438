/* eslint-disable import/no-extraneous-dependencies */
import { GridRowParams } from '@material-ui/data-grid';
import { format, getUnixTime, parseISO } from 'date-fns';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import TextArea from '@atlaskit/textarea';
import {
  BuscaParceiro,
  InputAsyncSelect,
  InputMoney,
  InputNumber,
  InputTextArea,
  Loja,
  ContaBanco,
  Categoria,
  Finalizadora,
} from '~/components/NovosInputs';
import { toast } from 'react-toastify';
// import Categoria from '~/components/Categoria';
// import ContaBanco from '~/components/ContaBanco';
// import Finalizadora from '~/components/Finalizadora';
import DefaultLoader from '~/components/DefaultLoader';

import { Container, ReceiptModal } from './styles';
import {
  Condicao,
  IContasReceber,
  Parcela,
  ReceiptData,
  Select,
} from './types';

import FormDefault from '~/components/FormDefault';

import InputSelect from '~/components/Inputs/InputSelect';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
// import Loja from '~/components/Loja';

import { InpuTypeDate } from '~/components/Pessoa/styles';
import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import {
  formatCurrencyAsText,
  getDataUtil,
  transformAsCurrency,
} from '~/utils/functions';
import { LojaContext } from '~/context/loja';
import ImpressaoRecibo from '~/utils/classes/ImpressaoRecibo/ImpressaoRecibo';
import { jsPDF } from 'jspdf';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlinePrinter } from 'react-icons/ai';
import { GoDownload } from 'react-icons/go';
import {
  UseFormRegisterReturn,
  Validate,
  ValidationRule,
  useForm,
} from 'react-hook-form';
import { SelectType } from '~/components/NovosInputs/BuscaParceiro/protocols';
import { InputDate } from '~/components/NovosInputs/InputDate';
import { useContasAReceber } from './ContasAReceberContext';
import { QueryClient, useQueryClient } from 'react-query';

import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const ContasAReceberContent: React.FC = () => {
  const queryClient = useQueryClient();
  const {
    formContasAReceber: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState: { errors },
      clearErrors,
      handleSubmit,
      trigger,
    },
  } = useContasAReceber();

  const [loader, setLoader] = useState<boolean>(false);
  const { codLoja, loja } = useContext(LojaContext);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [condicoes, setCondicoes] = useState<Select[]>([]);
  const [somaCondicao, setSomaCondicao] = useState(false);
  const [flgAceite, setFlgAceite] = useState<boolean>(false);
  const [errorParceiro, setErrorParceiro] = useState<boolean>(false);

  const [lojaRetriever, setLojaRetriever] = useState<number | null>(null);
  const [contaBancoRetriever, setContaBancoRetriever] = useState<number | null>(
    null,
  );
  const [categoriaRetriever, setCategoriaRetriever] = useState<number | null>(
    null,
  );
  const [finalizadoraRetriever, setFinalizadoraRetriever] = useState<
    number | null
  >(null);
  const [lojas, setLojas] = useState<number | number[]>();
  const [codSeqQuitacao, setCodSeqQuitacao] = useState<number>();
  const [receiptParcelaData, setReceiptParcelaData] = useState<Parcela[]>([]);
  const [recibo, setRecibo] = useState('');

  const flg_quitadoo = watch('flg_quitado');
  const flg_leitura_vdaa = watch('flg_leitura_vda');

  const [formData, setFormData] = useState<IContasReceber>({
    cod_conta_receber: {
      value: '',
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cod_loja: {
      value: codLoja,
      isInvalid: true,
      isRequired: true,
    },
    cod_cc: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_pessoa: {
      value: { label: undefined, value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_categoria: {
      value: { label: undefined, value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    val_parcela: {
      value: '0,00',
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    num_docto: {
      value: '',
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_finalizadora: {
      value: { value: undefined, label: '' },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    num_condicao: {
      value: 30,
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_condicao: {
      value: { value: 2, label: 'DIAS DA DATA - DD' },
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    flg_quitado: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_leitura_vda: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    num_pdv: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    num_cupom_fiscal: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
  });
  const [showModal, setShowModal] = useState(false);

  const [dataEmissao, setDataEmissao] = useState({
    value: '',
    isInvalid: true,
    isRequired: true,
    iniInicializado: false,
  });
  const [dataEntrada, setDataEntrada] = useState({
    value: '',
    isInvalid: true,
    isRequired: true,
    iniInicializado: false,
  });
  const [dataVencimento, setDataVencimento] = useState({
    value: '',
    isInvalid: true,
    isRequired: true,
    iniInicializado: false,
  });
  const [dataQuitada, setDataQuitada] = useState({
    value: '',
    isInvalid: false,
    isRequired: false,
    iniInicializado: false,
  });
  const [desObservacao, setDesObservacao] = useState({
    value: '',
    isRequired: false,
    isInvalid: false,
    iniInicializado: false,
  });

  const [formDisabled, setFormDisabled] = useState({
    dta_quitada: true,
  });

  const getCondicoes = async () => {
    try {
      const res = await api.get('/condicao');
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      const options: Select[] = data.map((condicao: Condicao) => ({
        label: `${condicao.des_condicao} - ${condicao.des_definicao}`,
        value: condicao.cod_condicao,
      }));
      setCondicoes(options);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCondicoes();

    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  }, []);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    reset({
      cod_loja: {
        value: undefined,
        label: 'Selecione',
      },
      cod_cc: {
        value: null,
        label: 'Selecione',
      },
      cod_categoria: {
        value: null,
        label: 'Selecione',
      },
      cod_finalizadora: {
        value: null,
        label: 'Selecione',
      },
      cod_pessoa: {
        value: null,
        label: '',
      },
      dta_emissao: '',
      dta_entrada: '',
      val_parcela: '',
      num_docto: null,
      condicao: null,
      dta_vencimento: '',
      flg_quitado: false,
      dta_quitada: '',
      flg_leitura_vda: false,
      num_pdv: 0,
      num_cupom_fiscal: 0,
      des_observacao: '',
    });
    setInitInicializado(false);
    setUpdate(false);
    clearErrors();
    setErrorParceiro(false);
    // setValue('cod_pessoa', {
    //   value: undefined,
    //   label: 'Informe pelo menos 3 caracteres do Cliente, Fornecedor ou Loja',
    // });
    setLojaRetriever(null);
    setContaBancoRetriever(null);
    setFormData({
      ...formData,
      cod_loja: {
        value: codLoja,
        isInvalid: true,
        isRequired: true,
      },
      cod_cc: {
        value: null,
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_categoria: {
        value: null,
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      val_parcela: {
        value: '0,00',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      num_docto: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_finalizadora: {
        value: null,
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      num_condicao: {
        value: 0,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_condicao: {
        value: { value: 2, label: 'DIAS DA DATA - DD' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_quitado: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_leitura_vda: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      num_pdv: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      num_cupom_fiscal: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });
    setDataEmissao({
      ...dataEmissao,
      value: '',
      isInvalid: true,
      isRequired: true,
      iniInicializado: true,
    });
    setDataEntrada({
      ...dataEntrada,
      value: '',
      isInvalid: true,
      isRequired: true,
      iniInicializado: false,
    });
    setDataVencimento({
      ...dataVencimento,
      value: '',
      isInvalid: true,
      isRequired: true,
      iniInicializado: false,
    });
    setDataQuitada({
      ...dataQuitada,
      value: '',
      isInvalid: false,
      isRequired: false,
      iniInicializado: false,
    });
    setDesObservacao({
      ...desObservacao,
      value: '',
      isInvalid: false,
      isRequired: false,
      iniInicializado: false,
    });
    setFlgAceite(false);
  }, [
    codLoja,
    dataEmissao,
    dataEntrada,
    dataQuitada,
    dataVencimento,
    desObservacao,
    formData,
    clearErrors,
    setValue,
  ]);

  const handleChangeFinalizadora2 = (newValue: any, isInvalid: boolean) => {
    setSomaCondicao(true);

    const object = condicoes.find(
      (condicao) => condicao.value === newValue.cod_condicao,
    );
    setValue('condicao', object);
    clearErrors('condicao');
    setFormData({
      ...formData,
      num_condicao: {
        ...formData.num_condicao,
        value: newValue.num_condicao,
        isInvalid: newValue.num_condicao < 1,
      },
    });
  };

  const handleChangeNumCondicao = useCallback(
    (val: number) => {
      let isInvalid = false;
      if (
        typeof transformAsCurrency(val || 0) !== 'number' &&
        transformAsCurrency(val || 0) < 0
      ) {
        isInvalid = true;
      }
      setFormData({
        ...formData,
        num_condicao: {
          ...formData.num_condicao,
          value: val,
          isInvalid,
        },
      });
    },
    [formData],
  );

  /**
   * Validações
   */
  const validaInputsParaSubmit = useCallback((): boolean => {
    setInitInicializado(false);
    const { cod_pessoa, cod_finalizadora } = getValues();
    const data = formData;
    let isInvalid = false;

    if (typeof cod_pessoa?.value !== 'number') {
      isInvalid = true;
      data.cod_pessoa.isInvalid = isInvalid;
      setErrorParceiro(true);
    }

    if (typeof data.cod_condicao.value.value !== 'number') {
      isInvalid = true;
      data.cod_condicao.isInvalid = isInvalid;
    }

    setFormData(data);
    return isInvalid;
  }, [formData, setFormData, getValues]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);
    setFlgAceite(true);

    const {
      cod_conta_receber,
      cod_banco_cc,
      cod_banco,
      cod_cc,
      des_cc,
      cod_loja,
      des_loja,
      des_loja_cidade,
      cod_pessoa,
      nome_pessoa,
      des_cidade,
      des_uf,
      cod_categoria,
      descricao,
      dta_emissao,
      dta_entrada,
      val_parcela,
      num_docto,
      cod_finalizadora,
      des_finalizadora,
      num_condicao,
      cod_condicao,
      dta_vencimento,
      flg_quitado,
      dta_quitada,
      flg_leitura_vda,
      num_pdv,
      num_cupom_fiscal,
      des_observacao,
      cod_seq_quitacao,
    } = row;
    setReceiptParcelaData([
      {
        cod_loja,
        num_docto,
        cod_pessoa,
        nome_pessoa,
        cod_finalizadora,
        des_finalizadora,
        val_parcela,
        val_juros: 0,
        dta_entrada,
        dta_vencimento,
      },
    ]);

    setCodSeqQuitacao(cod_seq_quitacao);
    setLojaRetriever(cod_loja);

    setFormData({
      ...formData,
      cod_conta_receber: {
        value: cod_conta_receber,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cod_loja: {
        value: cod_loja,
        isInvalid: false,
        isRequired: true,
      },
      cod_cc: {
        value: {
          value: cod_banco_cc,
          label: '',
        },
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_pessoa: {
        value: {
          value: cod_pessoa,
          label: `${nome_pessoa} ${
            des_cidade === null || des_uf === null
              ? ''
              : `- (${des_cidade} - ${des_uf})`
          }`,
        },
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_categoria: {
        value: {
          value: cod_categoria,
          label: descricao,
        },
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      val_parcela: {
        value: formatCurrencyAsText(val_parcela),
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      num_docto: {
        value: num_docto,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_finalizadora: {
        value: {
          value: cod_finalizadora,
          label: des_finalizadora,
        },
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      num_condicao: {
        value: num_condicao,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_condicao: {
        value:
          cod_condicao === 0
            ? { label: 'Dias da data', value: cod_condicao }
            : { label: 'Teste', value: cod_condicao },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_quitado: {
        value: flg_quitado,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_leitura_vda: {
        value: flg_leitura_vda,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      num_pdv: {
        value: num_pdv,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      num_cupom_fiscal: {
        value: num_cupom_fiscal,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });

    // Preencher Loja
    setLojaRetriever(cod_loja);
    setValue('cod_loja', {
      value: cod_loja,
      label: `${des_loja} (${des_loja_cidade})`,
    });

    // Preencher banco
    setValue('cod_cc', cod_banco_cc);
    setContaBancoRetriever(cod_banco_cc);

    // Preencher Parceiro
    setValue('cod_pessoa', {
      label: `${row.cod_pessoa} - ${nome_pessoa} ${
        des_cidade === null || des_uf === null
          ? ''
          : ` (${des_cidade} - ${des_uf})`
      }`,
      value: row.cod_pessoa,
    });

    // Preenchendo Categoria
    setValue('cod_categoria', { value: cod_categoria, label: descricao });

    // Preenchendo Finalizadora
    setValue('cod_finalizadora', {
      value: cod_finalizadora,
      label: des_finalizadora,
    });
    // handleChangeFinalizadora2({ cod_condicao: cod_condicao }, false);
    // setValue('cod_pessoa', cod_pessoa);

    setValue(
      'dta_emissao',
      format(createDateWithoutTimezone(dta_emissao), 'yyy-MM-dd'),
    );
    setValue(
      'dta_entrada',
      format(createDateWithoutTimezone(dta_entrada), 'yyy-MM-dd'),
    );
    setValue('val_parcela', formatCurrencyAsText(val_parcela));
    setValue('num_docto', num_docto);
    setValue('des_finalizadora', des_finalizadora);
    setValue(
      'condicao',
      condicoes.find((condicao: Select) => condicao.value === cod_condicao),
    );
    setValue('num_condicao', num_condicao);
    setValue(
      'dta_vencimento',
      format(createDateWithoutTimezone(dta_vencimento), 'yyy-MM-dd'),
    );
    setValue('flg_quitado', flg_quitado);
    setValue(
      'dta_quitada',
      dta_quitada === null
        ? ''
        : format(createDateWithoutTimezone(dta_quitada), 'yyy-MM-dd'),
    );
    setValue('flg_leitura_vda', flg_leitura_vda);
    setValue('num_pdv', num_pdv);
    setValue('num_cupom_fiscal', num_cupom_fiscal);
    setValue('des_observacao', des_observacao);

    setSomaCondicao(false);

    setLoader(false);
    setShowSearch(false);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
    setLojaRetriever(codLoja);
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  };

  const generateRecibo = useCallback(
    async (data: ReceiptData, baixar = false, show = false) => {
      const impressaoRecibo = new ImpressaoRecibo(data, receiptParcelaData);
      const reciboGerado = await impressaoRecibo.baixarRecibo(baixar);
      setRecibo(reciboGerado);
      if (show) setShowModal(true);
    },
    [receiptParcelaData],
  );

  const formatterRequestData = (data: any) => {
    return {
      cod_loja: data.cod_loja.value,
      cod_cc: data.cod_cc.value,
      cod_pessoa: data.cod_pessoa.value,
      cod_categoria: data.cod_categoria.value,
      dta_emissao: data.dta_emissao,
      dta_entrada: data.dta_entrada,
      val_parcela: data.val_parcela,
      num_docto: Number(data.num_docto),
      cod_finalizadora: data.cod_finalizadora.value,
      des_finalizadora: data.cod_finalizadora.label,
      num_condicao: formData.num_condicao.value,
      cod_condicao: formData.cod_condicao.value?.value,
      dta_vencimento: data.dta_vencimento,
      flg_quitado: data.flg_quitado,
      dta_quitada: data.dta_quitada,
      flg_leitura_vda: formData.flg_leitura_vda.value,
      num_caixa: data.num_caixa,
      num_pdv: data.num_caixa,
      num_cupom_fiscal: formData.num_cupom_fiscal.value,
      des_observacao: data.des_observacao,
    };
  };

  const onSave = handleSubmit(async (data) => {
    if (validaInputsParaSubmit()) {
      setInitInicializado(true);
      return;
    }

    if (
      getUnixTime(parseISO(data.dta_entrada)) <
      getUnixTime(parseISO(data.dta_emissao))
    ) {
      setLoader(false);
      toast.warning(
        'Data de entrada deve ser maior ou igual à data de emissão',
      );
      return;
    }

    if (
      getUnixTime(parseISO(data.dta_vencimento)) <
      getUnixTime(parseISO(data.dta_emissao))
    ) {
      setLoader(false);
      toast.warning(' Vencimento deve ser maior ou igual a data de emissao');
      return;
    }

    if (
      getUnixTime(parseISO(data.dta_quitada)) <
      getUnixTime(parseISO(data.dta_entrada))
    ) {
      setLoader(false);
      toast.warning(
        'Data de Quitação deve ser maior ou igual à Data de Entrada',
      );
      return;
    }

    const requestData = formatterRequestData(data);
    if (isUpdate) {
      const res = await api.put(
        `/contas-receber/${formData?.cod_conta_receber.value}`,
        requestData,
      );

      if (res.data.success) {
        toast.success(res.data.message);
        if (formData.flg_quitado.value) {
          generateRecibo(res.data.data[0], false, true);
        }
        resetFormData();
        if (!formData.flg_quitado.value) setShowSearch(true);
        return;
      }
    }

    const res = await api.post('/contas-receber', requestData);

    if (res.data.success) {
      if (formData.flg_quitado.value) {
        generateRecibo(res.data.data[0], false, true);
      }
      resetFormData();
      toast.success(res.data.message);
    } else {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const { data } = await api.delete(
      `/conta-receber/${formData?.cod_conta_receber.value}`,
    );

    if (data.success) {
      resetFormData();
      setShowSearch(true);
      setUpdate(false);
      toast.success('Conta removida com sucesso');
    }
  };

  const getReceipt = async () => {
    try {
      const res = await api.get('/quitacao/recibo', {
        params: {
          cod_seq_quitacao: codSeqQuitacao,
        },
      });

      const { success, message } = res.data;
      if (!success) throw new Error(message);
      if (res.data.data.length > 0) generateRecibo(res.data.data[0], true);
    } catch (error: any) {
      toast.warning(error.data);
    }
  };

  const gerarEbaixarPDF = (receipt?: string, imprimir = false) => {
    const receiptInText = receipt || recibo;
    const linhas = receiptInText.split('\n');
    const numeroDeLinhas = linhas.length;

    const espacamentoEntreLinhas = 5; // Ajuste este valor conforme necessário
    const alturaPagina = numeroDeLinhas * espacamentoEntreLinhas + 20; // + 20mm de margem
    const larguraPagina = 80;

    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      // format: [4 * 25.4, 6 * 25.4], // Aproximadamente 4x6 polegadas
      format: [larguraPagina, alturaPagina],
    });

    doc.setFont('Courier'); // Define a fonte para Courier
    doc.setFontSize(8); // Define o tamanho da fonte
    doc.setTextColor(0, 0, 0); // Define a cor do texto para preto

    linhas.forEach((linha, index) => {
      doc.text(linha, 0, 10 + index * espacamentoEntreLinhas);
    });

    if (imprimir) {
      // Obtém o conteúdo do PDF como uma string
      const pdfContent = doc.output();

      // Abre o conteúdo do PDF em uma nova janela ou guia
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        newWindow.document.write(
          '<html><head><title>Recibo</title></head><body style="margin: 0; padding: 0;">',
        );
        newWindow.document.write(
          `<embed width="100%" height="100%" name="plugin" src="data:application/pdf;base64,${btoa(
            pdfContent,
          )}" type="application/pdf">`,
        );
        newWindow.document.write('</body></html>');
      } else {
        toast.error('Não foi possível abrir o recibo para impressão.');
      }
      return;
    }
    doc.save('recibo.pdf');
  };

  useEffect(() => {
    const numCondicao = formData.num_condicao.value;
    const emissao = getValues('dta_emissao');

    if (numCondicao && emissao && somaCondicao) {
      const diaUtil = getDataUtil(emissao, numCondicao);
      setValue('dta_vencimento', diaUtil);
      // setDataVencimento((prev) => {
      //   return {
      //     ...prev,
      //     value: diaUtil,
      //   };
      // });
    }
  }, [formData.num_condicao.value, somaCondicao]);

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={46}
          newData={() => {
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={46}
          title="Contas a Receber"
          onSave={async (data) => {
            if (validaInputsParaSubmit()) {
              setInitInicializado(true);
            }
            await onSave();
          }}
          codigoRegistro={[
            {
              value: formData.cod_conta_receber.value,
              des_campo: 'Código',
            },
          ]}
          onCancel={() => {
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
            setLojaRetriever(codLoja);
            setValue('cod_loja', {
              value: loja.cod_loja,
              label: `${loja.des_loja} (${loja.des_cidade})`,
            });
            queryClient.removeQueries('cache_bancos');
          }}
          showSwitch={false}
          showReceiptButton={!!codSeqQuitacao && formData.flg_quitado.value}
          getReceipt={() => getReceipt()}
        >
          <>
            <div className="row">
              <div
                className={`col-sm-12 col-md-8 ${
                  formData.cod_loja.value === undefined &&
                  iniInicializado === true
                    ? 'loja'
                    : ''
                }`}
                style={{ zIndex: 3 }}
              >
                <Loja
                  label="Loja"
                  placeholder="Selecione"
                  register={register}
                  isError={!!errors.cod_loja}
                  control={control}
                  name="cod_loja"
                  changeSelected={(selected: any) => {
                    clearErrors('cod_loja');
                    setLojaRetriever(selected.cod_loja);
                    setValue('cod_loja', selected);
                    setValue('cod_cc', {
                      value: null,
                      label: 'Selecione',
                    });
                    setValue('cod_finalizadora', {
                      value: null,
                      label: 'Selecione',
                    });
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4" style={{ zIndex: 2 }}>
                <ContaBanco
                  label="Conta"
                  placeholder="Selecione"
                  register={register}
                  isError={!!errors.cod_cc}
                  control={control}
                  name="cod_cc"
                  loja={lojaRetriever}
                  retriever={contaBancoRetriever}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_cc');
                    setContaBancoRetriever(null);
                    setValue('cod_cc', selected);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-12" style={{ zIndex: 1 }}>
                <BuscaParceiro
                  label="Parceiro"
                  placeholder="Informe pelo menos 3 caracteres do Cliente, Fornecedor ou Loja"
                  name="cod_pessoa"
                  register={register}
                  isError={!!errors.cod_pessoa}
                  control={control}
                  customOptions={{
                    buscarPor: { cliente: true, fornecedor: true, loja: true },
                  }}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_pessoa');
                    const cod = selected.cod_pessoa ? selected.cod_pessoa : '';
                    const nome = selected.nome_pessoa
                      ? selected.nome_pessoa
                      : '';
                    const cidade = selected.des_cidade
                      ? ` (${selected.des_cidade} - ${selected.des_uf})`
                      : '';
                    setValue('cod_pessoa', {
                      value: selected.value,
                      label: `${cod} - ${nome} ${cidade}`,
                    });
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <Categoria
                  label="Categoria"
                  placeholder="Selecione"
                  tipoCategoria="Receita"
                  register={register}
                  isError={!!errors.cod_categoria}
                  control={control}
                  name="cod_categoria"
                  retriever={categoriaRetriever}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_categoria');
                    setValue('cod_categoria', selected);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Emissão"
                  placeholder=""
                  name="dta_emissao"
                  register={register}
                  control={control}
                  isError={!!errors.dta_emissao}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Entrada"
                  placeholder=""
                  name="dta_entrada"
                  register={register}
                  control={control}
                  isError={!!errors.dta_entrada}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputMoney
                  label="Valor"
                  min={0.0}
                  max={999999.01}
                  placeholder="0,00"
                  register={register}
                  name="val_parcela"
                  isError={!!errors.val_parcela}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputNumber
                  name="num_docto"
                  label="Nº Docto"
                  register={register}
                  isError={!!errors.num_docto}
                  min={0}
                  max={999999999999999}
                  maxLength={15}
                  placeholder="Informe o Nº Docto"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Finalizadora
                  label="Finalizadora"
                  placeholder="Selecione"
                  register={register}
                  isError={!!errors.cod_finalizadora}
                  control={control}
                  name="cod_finalizadora"
                  loja={lojaRetriever}
                  changeSelected={(selected: any) => {
                    clearErrors('cod_finalizadora');
                    setValue('cod_finalizadora', selected);
                    handleChangeFinalizadora2(
                      selected,
                      typeof selected.value !== 'number',
                    );
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <div>
                  <InputNumberDias
                    label="Condição"
                    min={0}
                    max={999}
                    value={formData.num_condicao.value}
                    placeholder=""
                    isNull
                    isDisabled={flgAceite}
                    isRequired={formData.num_condicao.isRequired}
                    setInvalid={formData.num_condicao.isInvalid}
                    iniInicializado={iniInicializado}
                    onChange={(newValue: number) => {
                      handleChangeNumCondicao(Math.abs(newValue));
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <InputAsyncSelect
                  label="Tipo de Condição"
                  maxLength={50}
                  placeholder="Selecione a Condição"
                  name="condicao"
                  register={register}
                  isError={!!errors.condicao}
                  control={control}
                  changeSelected={(selected) => {
                    clearErrors('condicao');
                    setValue('condicao', selected);
                  }}
                  api={{
                    route: '/condicao',
                    method: 'get',
                    fields: ['des_definicao', 'des_condicao'],
                    searchBeforeFilter: true,
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Vencimento"
                  placeholder=""
                  name="dta_vencimento"
                  register={register}
                  control={control}
                  isError={!!errors.dta_vencimento}
                />
              </div>
              <div className="col-sm-12 col-md-1">
                <ToggleDefault
                  labelText="Quitado?"
                  setChecked={flg_quitadoo}
                  onSwitch={() => setValue('flg_quitado', !flg_quitadoo)}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <InputDate
                  label="Quitação"
                  placeholder=""
                  name="dta_quitada"
                  register={register}
                  control={control}
                  isError={!!errors.dta_quitada}
                  disabled={!flg_quitadoo}
                />
              </div>
              {isUpdate && (
                <>
                  <div
                    className="col-sm-12 col-md-1"
                    style={{ textAlign: 'center', zIndex: 0 }}
                  >
                    <ToggleDefault
                      labelText="Automático?"
                      disabled
                      setChecked={flg_leitura_vdaa}
                      onSwitch={() =>
                        setValue('flg_leitura_vda', !flg_leitura_vdaa)
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-1">
                    <InputNumber
                      label="PDV"
                      name="num_pdv"
                      isError={!!errors.num_pdv}
                      placeholder=""
                      min={0}
                      max={99999999999}
                      maxLength={9999999999}
                      register={register}
                      disabled
                    />
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <InputNumber
                      label="Cupom"
                      name="num_cupom_fiscal"
                      placeholder=""
                      isError={false}
                      min={0}
                      max={99999999999}
                      maxLength={9999999999}
                      register={register}
                      disabled
                    />
                  </div>
                </>
              )}
              <div
                className="col-sm-12 col-md-12"
                style={{ marginTop: '20px' }}
              >
                <div>
                  <InputTextArea
                    maxLength={500}
                    label=""
                    placeholder="Informe a Observação"
                    name="des_observacao"
                    register={register}
                    isError={false}
                    control={control}
                    rows={4}
                    resize={false}
                  />
                </div>
              </div>
            </div>
            <ReceiptModal
              show={showModal}
              onHide={() => {
                setShowModal(false);
              }}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="lg"
              // backdrop={!loading || 'static'}
            >
              <Modal.Header>
                <Modal.Title>Visualização do Recibo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  className="receipt"
                  style={{
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'Courier New, monospace',
                    fontSize: '12px',
                    lineHeight: '1.6',
                    letterSpacing: '0px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'black',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid grey',
                      paddingLeft: '35px',
                      paddingTop: '20px',
                      paddingBottom: '40px',
                      paddingRight: '5px',
                    }}
                  >
                    {recibo}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    gerarEbaixarPDF(recibo, true);
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '3px',
                  }}
                >
                  Imprimir
                  <AiOutlinePrinter size={24} className="btn-icon" />
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    gerarEbaixarPDF(recibo);
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '3px',
                  }}
                >
                  Baixar
                  <GoDownload size={24} className="btn-icon" />
                </Button>
              </Modal.Footer>
            </ReceiptModal>
          </>
        </FormDefault>
      )}
    </Container>
  );
};

export default ContasAReceberContent;
